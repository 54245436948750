import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 1s;
  > div {
    position: fixed;
    z-index: 999;
    top: calc(50% - 60px);
    transform: translateY(-50%);
    width: 100vw;
    height: 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    transition: all 1s;
    /* justify-content: center; */
    overflow: visible;
  }
  .app-sidebar {
    #title-bot-id {
      position: absolute;
      white-space: nowrap;
      bottom: -100px;
      min-width: 181px;
      text-align: end;
    }
    margin-right: -25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    -moz-user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* height: 60vh; */
    /* min-height: 300px; */
    justify-content: space-between;
    height: calc(100vh - 220px);
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          margin-right: -16px;
          justify-content: center;
          height: fit-content;
    `}
    .sidebar-bot {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 70px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          display:none;
    `}

      .title {
        font-family: "Clash Display";
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 32px;
        /* identical to box height, or 320% */

        display: flex;
        align-items: center;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        justify-content: flex-end;

        color: #ffffff;

        transform: translateY(-100%) rotate(90deg);
      }
    }
    .sidebar-top {
      .progress {
        mix-blend-mode: screen;
        height: 86px;
        width: 5px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -20px;
        backdrop-filter: blur(22px);
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          display:none;
    `}
        > img {
          height: 100px;
          width: auto;
          mix-blend-mode: screen;
          background-color: transparent;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;
      .title {
        margin-top: 100px;
        font-family: "Clash Display";
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.5em;
        text-transform: uppercase;
        color: #ffffff;
        transform: rotate(90deg);
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          display:none;
    `}
      }
      .social {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        > img {
          cursor: pointer;
          width: 24px;
        }
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
         gap: 16px;
         background: rgba(155, 165, 177, 0.1);
          box-shadow: 0px 0px 13px #000000;
          backdrop-filter: blur(20px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 20px 0px 0px 20px;
          padding: 8px 10px 8px 8px;
    `}
      }
    }
  }
`;
