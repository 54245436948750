import React, { useCallback, useRef } from "react";
import { Wrapper } from "./CustomHeader.styled";
import { withAccount, withConnect } from "src/hoc";
import { onMoveAnimation } from "src/services/useDevelopUI";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PieChartIcon from "@mui/icons-material/PieChart";
import { Link, useHistory } from "react-router-dom";
import { useOutsideClickRef } from "src/services/hooks/useOutsideClickRef";
import { formatAddressToHuman } from "src/services/utils/format";
import { ENVS } from "src/configs/Configs.env";
import { Mouse } from "../Mouse/Mouse";

const CustomHeader = ({
  onOpenConnectModal,
  onOpenPopupConnect,
  onClosePopupConnect,
  onCheckBeforeRequest,
  profile,
  account,
  disconnect,
  onSigninWallet,
  changeChain,
  isConnectedCompatibleChain,
  token,
}: any) => {
  const refPopupConnect = useRef(null);
  const history = useHistory();
  const { data: profileData } = profile;
  useOutsideClickRef(refPopupConnect, onClosePopupConnect);

  const onRedirectToLogin = useCallback(() => {
    history.push("/authenticate?type=login");
  }, [history]);

  const onChangeChain = useCallback(
    () => changeChain(ENVS.REACT_APP_BASE_CHAIN_ID),
    [changeChain]
  );

  return (
    <Wrapper>
      <div id="app-header-id" className={`app-header `}>
        <div className="container">
          <div className="icon-lefts">
            <img
              onClick={() => history.push("/")}
              src={require("../../assets/header/homewave.png").default}
              alt=""
            />
          </div>
          <div
            className="btn-center"
            onClick={() => {
              window.open("https://tuniver.io/");
            }}
          >
            Claim your Royalty share from Binz campaign
          </div>
          <div className="icon-rights">
            {!token ? (
              <div onClick={onCheckBeforeRequest} className="btn-rights">
                Join us now
              </div>
            ) : (
              <div className="account">
                <div className="title">
                  <div className="avatar">
                    <img
                      src={require("../../assets/navbar/avatar.png").default}
                      alt="Avatar"
                    />
                  </div>
                  <span>Hi, {profileData?.username || "Tuniverer"}</span>
                  <KeyboardArrowDownIcon />
                </div>
                <div className="menu-container">
                  <div className="menu">
                    <Link to="/" className="menu-item">
                      <PieChartIcon /> Dashboard
                    </Link>
                    <Link onClick={disconnect} to="/" className="menu-item">
                      <LogoutIcon /> Logout
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <div className="wallet-container">
              <IconButton onClick={onOpenPopupConnect} className="btn-icon">
                {!account ? (
                  <img
                    className="wallet-icon"
                    src={require("../../assets/header/wallet.png").default}
                    alt="Wallet"
                  />
                ) : (
                  <img
                    className="wallet-icon"
                    src={
                      require("../../assets/header/wallet-active.png").default
                    }
                    alt="Wallet"
                  />
                )}
              </IconButton>
              <div
                ref={refPopupConnect}
                id="connect-popup"
                className="wallet-status"
              >
                <div className="header">
                  <span className="title">Your Wallet</span>
                </div>
                <span className="subtitle">
                  You need a crypto wallet to connect with your favorite artists
                  on Tuniver. <Link to="/">Learn more.</Link>
                </span>
                {account && (
                  <div className="wallet-address">
                    <img
                      src={
                        require("../../assets/header/logo-network.png").default
                      }
                      alt="Logo"
                    />
                    <div className="address">
                      <span className="network">Binance</span>
                      <span className="account">
                        {formatAddressToHuman(account)}
                      </span>
                    </div>
                  </div>
                )}
                {!account ? (
                  <div onClick={onOpenConnectModal} className="btn">
                    <Mouse id="mouse-chain" />
                    Connect Wallet
                  </div>
                ) : (
                  <>
                    {!token && (
                      <div onClick={onSigninWallet} className="btn-sign">
                        <Mouse id="mouse-sign" />
                        Sign In
                      </div>
                    )}
                    {!isConnectedCompatibleChain && (
                      <div onClick={onChangeChain} className="btn-sign">
                        <Mouse id="mouse-chain" />
                        Change Compatible Network
                      </div>
                    )}
                    <div onClick={disconnect} className="btn">
                      Disconnect Wallet
                    </div>
                  </>
                )}
              </div>
            </div>

            <img
              src={require("../../assets/header/Group 481302.png").default}
              alt=""
              onClick={() => {
                onMoveAnimation("nav-bar-id", "moveInOpacity");
              }}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default withConnect(withAccount(CustomHeader));
