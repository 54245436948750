import { ChainId, SUPPORTED_CHAINID } from "./Configs.chainId";

const rpc = {
  [ChainId.MAINNET]:
    "https://eth-mainnet.g.alchemy.com/v2/FDJoIBGZ1hJsEtiwAtdpRCFoyjdDCs96",
  [ChainId.ROPSTEN]:
    "https://eth-ropsten.alchemyapi.io/v2/cidKix2Xr-snU3f6f6Zjq_rYdalKKHmW",
  [ChainId.RINKEBY]:
    "https://eth-rinkeby.alchemyapi.io/v2/XVLwDlhGP6ApBXFz_lfv0aZ6VmurWhYD",
  [ChainId.GÖRLI]:
    "https://eth-goerli.alchemyapi.io/v2/Dkk5d02QjttYEoGmhZnJG37rKt8Yl3Im",
  [ChainId.KOVAN]:
    "https://eth-kovan.alchemyapi.io/v2/6OVAa_B_rypWWl9HqtiYK26IRxXiYqER",
  [ChainId.FANTOM]: "https://rpcapi.fantom.network",
  [ChainId.FANTOM_TESTNET]: "https://rpc.testnet.fantom.network",
  [ChainId.MATIC]: "https://rpc-mainnet.maticvigil.com",
  [ChainId.MATIC_TESTNET]: "https://rpc-mumbai.matic.today",
  [ChainId.XDAI]: "https://rpc.xdaichain.com",
  [ChainId.BSC]: "https://bsc-dataseed.binance.org/",
  [ChainId.BSC_TESTNET]: "https://data-seed-prebsc-2-s3.binance.org:8545",
  [ChainId.MOONBEAM_TESTNET]: "https://rpc.testnet.moonbeam.network",
  [ChainId.AVALANCHE]: "https://api.avax.network/ext/bc/C/rpc",
  [ChainId.AVALANCHE_TESTNET]: "https://api.avax-test.network/ext/bc/C/rpc",
  [ChainId.HECO]: "https://http-mainnet.hecochain.com",
  [ChainId.HECO_TESTNET]: "https://http-testnet.hecochain.com",
  [ChainId.HARMONY]: "https://api.harmony.one",
  [ChainId.HARMONY_TESTNET]: "https://api.s0.b.hmny.io",
  [ChainId.OKEX]: "https://exchainrpc.okex.org",
  [ChainId.OKEX_TESTNET]: "https://exchaintestrpc.okex.org",
  [ChainId.ARBITRUM]: "https://arb1.arbitrum.io/rpc",
  [ChainId.PALM]:
    "https://palm-mainnet.infura.io/v3/da5fbfafcca14b109e2665290681e267",
  [ChainId.CELO]: "https://forno.celo.org",
};

export const connectors = {
  injected: {
    chainId: SUPPORTED_CHAINID,
  },
  fortmatic: {
    chainId: SUPPORTED_CHAINID,
    apiKey: "",
  },
  portis: {
    dAppId: "",
    chainId: SUPPORTED_CHAINID,
  },
  walletconnect: {
    rpc,
    bridge: "https://bridge.walletconnect.org",
    pollingInterval: 12000,
  },
  walletlink: {
    chainId: 1,
    url: "https://mainnet.infura.io/v3/4123de0a41ff46fba678367906f35a42",
  },
};

export default rpc;
