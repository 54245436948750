import React from "react";
import { toast } from "react-toastify";
import { Wrapper } from "./Sidebar.styled";

const Sidebar = () => {
  return (
    <Wrapper id="sidebar">
      <div className="container">
        <div className="app-sidebar">
          <div className="sidebar-top">
            <div className="title">SHARE</div>
            <div className="social">
              <img
                onClick={() => {
                  window.open("https://www.facebook.com/tuniver.io");
                }}
                src={
                  require("../../assets/navbar/Facebook - Negative.png").default
                }
                alt=""
              />
              <img
                onClick={() => {
                  window.open("https://twitter.com/TuniverOfficial");
                }}
                src={
                  require("../../assets/navbar/Twitter - Negative.png").default
                }
                alt=""
              />
              <img
                onClick={() => {
                  window.open("https://t.me/tunivervietnam_official");
                }}
                src={require("../../assets/navbar/Subtract.png").default}
                alt=""
              />
              <img
                onClick={() => {
                  toast.info("Discord is not available now!");
                }}
                src={require("../../assets/navbar/Vector (1).png").default}
                alt=""
              />
            </div>
            <div className="progress">
              {
                <img
                  src={require("../../assets/sidebar/progress.gif").default}
                  alt=""
                />
              }
            </div>
          </div>
          <div className="sidebar-bot">
            <div className="title" id="title-bot-id">
              Scroll down to discover
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Sidebar;
