import { MEDIA_WIDTHS } from "src/theme";

export const onMoveAnimation = (_id: string, animation: string) => {
  const root = document.querySelector(`#${_id}`) as HTMLElement;
  try {
    if (root) {
      root.style.animation = `${animation} .3s`;
      root.style.animationFillMode = "forwards";
    }
  } catch (err: any) {
    console.log(err.message);
  }
};
export const onhandleProgressbar = (_id: string, animation: string) => {
  const root = document.querySelector(`#${_id}`) as HTMLElement;
  try {
    if (root) {
      root.style.animation = `${animation} .3s`;
      root.style.animationFillMode = "forwards";
    }
  } catch (err: any) {
    console.log(err.message);
  }
};

export const handleShowDisk = () => {
  let screenHeight = window.innerHeight;
  const footer = document.querySelector("#app-footer-id");
  if (footer) {
    screenHeight =
      (document.body.clientHeight - footer.getBoundingClientRect().height) / 8;
  }
  const scrollY = window.scrollY;
  const rate = scrollY / screenHeight;
  const disk = document.querySelector("#disk") as HTMLElement;
  const diskTop = document.querySelector("#disk .disk-top") as HTMLElement;
  const sidebar = document.querySelector("#sidebar") as HTMLElement;
  const titleBot = document.querySelector("#title-bot-id") as HTMLElement;
  const conTent1 = document.querySelector(".content-1") as HTMLElement;
  const conTent2 = document.querySelector(".content-2") as HTMLElement;
  const conTent3 = document.querySelector(".content-3") as HTMLElement;
  const diskBg = document.querySelector("#disk-bg-bg") as HTMLElement;
  const progressLeft = document.querySelector(
    "#progress-left-id"
  ) as HTMLElement;
  const progressBottom = document.querySelector(
    "#progress-bottom-id"
  ) as HTMLElement;

  if (rate < 0.9) {
    titleBot.innerHTML = "Scroll down to discover";
  } else {
    titleBot.innerHTML = "inside platform";
  }
  if (rate >= 0.8 && rate < 1.5) {
    disk.style.zIndex = "1";
  } else {
    disk.style.zIndex = "0";
  }
  if (rate > 1.3) {
    conTent1.style.display = "none";
    conTent2.style.display = "none";
    conTent3.style.display = "none";
  } else {
    conTent1.style.display = "block";
    conTent2.style.display = "block";
    conTent3.style.display = "block";
  }
  if (rate >= 0 && rate < 1.5) {
    sidebar.style.opacity = "1";
    progressBottom.style.opacity = "1";
  } else {
    sidebar.style.opacity = "0";
    progressBottom.style.opacity = "0";
  }
  if (window.innerWidth < MEDIA_WIDTHS.upToExtraSmall) {
    if (rate >= 0 && rate < 0.9) {
      sidebar.style.opacity = "1";
    } else {
      sidebar.style.opacity = "0";
    }
  }
  if (rate >= 1) {
    progressLeft.style.opacity = "1";
    let firstChild = progressLeft?.firstChild?.firstChild as HTMLElement;
    let secondChind = progressLeft?.firstChild?.lastChild as HTMLElement;
    if (rate >= 2) {
      firstChild.innerHTML = "02";
    } else {
      firstChild.innerHTML = "01";
      secondChind.style.transform = "rotate(0deg)";
    }
    if (rate >= 3) {
      firstChild.innerHTML = "03";
      secondChind.style.transform = "rotate(90deg)";
    }
    if (rate >= 4) {
      firstChild.style.maxWidth = "0";
      firstChild.style.marginRight = "0";
    } else {
      firstChild.style.maxWidth = "unset";
      firstChild.style.marginRight = "46px";
    }
    if (rate >= 7) {
      secondChind.style.transform = "rotate(-90deg)";
    }
    if (rate >= 7.3) {
      progressLeft.style.bottom = "300px";
    } else {
      progressLeft.style.bottom = "100px";
    }
  } else {
    progressLeft.style.opacity = "0";
  }
  if (rate <= 1) {
    diskBg.style.opacity = `1`;
    let top;
    let top1 = 73;
    if (window.innerWidth > MEDIA_WIDTHS.upToLarge) {
      top = 0;
    } else {
      if (window.innerHeight > 900) {
        top = -3;
        if (window.innerHeight > 1200) {
          top = -10;
          top1 = 70;
        }
      } else {
        if (window.innerHeight < 900) {
          top = 0;
          top1 = 80;
        }
        if (window.innerHeight < 700) {
          top = 0;
          top1 = 86;
        }
        if (window.innerHeight < 670) {
          top = 0;
          top1 = 91;
        }
      }
    }
    if (window.innerWidth <= MEDIA_WIDTHS.upToExtraSmall) {
      if (window.innerHeight >= 600) {
        top1 = 28;
        top = 24;
      } else {
        top1 = 23;
        top = 24;
      }
    }
    diskTop.style.opacity = `${rate}`;
    disk.style.transform = `translate(-50%, -50%) rotateX(${
      -65 * (1 - rate)
    }deg)`;
    disk.style.top = `${top * rate + top1}%`;
  } else if (rate <= 2) {
    let top;
    let top1;
    if (window.innerWidth > MEDIA_WIDTHS.upToLarge) {
      top = 43;
      top1 = 75;
    } else {
      if (window.innerHeight > 900) {
        top = 48;
        top1 = 70;
        if (window.innerHeight > 1200) {
          top = 40;
          top1 = 60;
        }
      } else {
        if (window.innerHeight < 900) {
          top = 50;
          top1 = 79;
        }
        if (window.innerHeight < 700) {
          top = 51;
          top1 = 86;
        }
        if (window.innerHeight < 670) {
          top = 50;
          top1 = 91;
        }
      }
    }
    if (window.innerWidth <= MEDIA_WIDTHS.upToExtraSmall) {
      if (window.innerHeight >= 600) {
        top1 = 56;
        top = 58;
        if (window.innerHeight >= 800) {
          top1 = 56;
          top = 48;
        }
      } else {
        top1 = 56;
        top = 58;
      }
    }

    const rateSection = rate - 1;
    disk.style.top = `${top * rateSection + top1}%`;
    disk.style.transform = `translate(-50%, -50%) rotateX(0deg) rotate(${
      rateSection * 50
    }deg)`;
    diskBg.style.opacity = `${2 - rate * 1}`;
  }
  // else if (rate <= 3) {
  //   diskBg.style.opacity = `0`;
  // }
  else if (rate <= 3) {
    diskBg.style.opacity = `0`;
    const rateSection = 3 - rate;
    disk.style.transform = `translate(-50%, -50%) rotateX(0deg) rotate(${
      rateSection * 50
    }deg)`;
  } else if (rate <= 4) {
    diskBg.style.opacity = `0`;
    const rateSection = rate - 3;
    let top;

    if (window.innerWidth > MEDIA_WIDTHS.upToLarge) {
      top = 118;
    } else {
      if (window.innerHeight > 900) {
        top = 118;
        if (window.innerHeight > 1200) {
          top = 99.97;
        }
      } else {
        if (window.innerHeight < 900) {
          top = 129.124;
        }
        if (window.innerHeight < 700) {
          top = 136.92;
        }
        if (window.innerHeight < 670) {
          top = 140.92;
        }
      }
    }
    if (window.innerWidth <= MEDIA_WIDTHS.upToExtraSmall) {
      if (window.innerHeight >= 600) {
        top = 113.88;
        if (window.innerHeight >= 800) {
          top = 103.941;
        }
      } else {
        top = 140.917;
      }
    }

    disk.style.top = `${top}%`;
    disk.style.transform = `translate(-50%, -50%) rotateX(0deg) rotate(${
      rateSection * -53
    }deg)`;
  } else if (rate <= 5) {
    diskBg.style.opacity = `0`;
    let topRoot =
      window.innerWidth > MEDIA_WIDTHS.upToLarge
        ? 118
        : window.innerHeight > 900
        ? 118
        : 127;
    if (window.innerWidth > MEDIA_WIDTHS.upToLarge) {
      topRoot = 123;
    } else {
      if (window.innerHeight > 900) {
        topRoot = 118;
        if (window.innerHeight > 1200) {
          topRoot = 99.97;
        }
      } else {
        topRoot = 129.124;
      }
    }
    if (window.innerWidth <= MEDIA_WIDTHS.upToExtraSmall) {
      topRoot = 113.88;
    }
    const rateSection = rate - 4;
    disk.style.top = `${topRoot - 68 * rateSection}%`;
    disk.style.opacity = `${1 - rateSection}`;
  } else {
    diskBg.style.opacity = `0`;
    disk.style.opacity = "0";
  }
};

export function doScrolling(elementY, duration) {
  let startingY = window.pageYOffset;
  let diff = elementY - startingY;
  let start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    let time = timestamp - start;
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}
