import { lazy } from "react";
import { IRouteProps } from "src/modules";

export const route = "/tunipass";

const TunipassRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import("src/modules/Tunipass")),
  name: "Tunipass",
};

export default TunipassRoute;
