import React, { memo } from "react";
import { Wrapper } from "./Loading.styled";

interface ILoadingProps {
  percent: number;
}

const LoadingComponent = ({ percent }: ILoadingProps) => {
  return (
    <Wrapper id="loading">
      <video width="183" autoPlay loop muted playsInline>
        <source
          src={require("../../assets/loading/loading.webm").default}
          type="video/webm"
        />
      </video>
      <div className="progress-container">
        <div className="progress">
          <div style={{ width: `${percent}%` }} className="overlay" />
        </div>
      </div>
    </Wrapper>
  );
};

export const Loading = memo(LoadingComponent);
